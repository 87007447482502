import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import getManualInvoice from "services/Billing/getManualInvoice"
import getStripeInvoice from "services/Billing/getStripeInvoice"
import uploadPayment from "services/Billing/manualPayment"
import { useAppContext } from "./app"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { Routes } from "constants/routes"

export const useManualUpload  = () =>{
  const { setAppState, closeModal } = useAppContext()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  let id: any = null;

  const onMutate = () => {
    id = toast.loading("submitting request ...")
  }

    const onSuccess = (data: any) => {
      toast.dismiss(id);
      closeModal?.();
      if(!data?.inReviewInvoice?.id) {
        setAppState?.(prev => ({ ...prev, hasCompletedPayment: true }))
        navigate(Routes.THANK_YOU+'?plan='+data?.inReviewInvoice?.plan+'&amount='+data?.inReviewInvoice?.amount)
        queryClient.invalidateQueries(['getManualInvoices']);
        queryClient.invalidateQueries(['getStripeInvoices']);
        queryClient.invalidateQueries(['getSubscription']);
      }
    }

    const onError = (error:any) => {
      toast.dismiss(id)
      toast.error(error?.response?.data?.message || "There was some unknown error")
    }

    const { mutateAsync, isLoading } = useMutation(uploadPayment, { onSuccess, onError, onMutate })
    return { upload: mutateAsync, isLoading }
}

export const useGetManualInvoices = () => {
    const { data, isLoading } = useQuery(["getManualInvoices"], getManualInvoice, {
      refetchOnWindowFocus: true,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false,
    });
    return { manualInvoices: data, isLoading };
};

export const useGetStripeInvoices = () => {
    const { data, isLoading } = useQuery(["getStripeInvoices"], getStripeInvoice, {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false,
    });
    return { stripeInvoices: data, isLoading };
};
